<template>
  <div class="orderDialog__availableProducts mt-2">
    <v-row class="pa-0 ma-0" justify="space-between" align="center">
      <!-- <v-col cols="2" class="pt-0 pl-0">
        <VBtn class="br-10 text-transform-none" color="primary" plain @click="$emit('back')">
          <VIcon left>
            mdi-arrow-left
          </VIcon>
          {{ $t("btn.back") }}
        </VBtn>
      </v-col> -->
      <v-col cols="2" class="px-2 py-1">
        <VBtn depressed plain small color="success" @click="onBack">
          <VIcon left>mdi-arrow-left</VIcon>
          {{ $t("btn.back") }}
        </VBtn>
      </v-col>
        <v-col cols="auto" class="px-2 py-1">
      <v-checkbox class="ml-4" v-model="imagesCheckbox" :label="$t('crm.show_pictures')"  hide-details color="success"></v-checkbox>
      </v-col>
      <v-col cols="auto" class="px-2 py-1 ml-auto">
         <v-btn-toggle v-if="crmTabVision"
          mandatory
          v-model="productsCategory"
          color="success"
          dense
          borderless
          class="text-transform-none"
        >
          <VBtn value="deport" width="120px" class="text-transform-none">
            {{ $t('crm.suit.edit.items') }}
          </VBtn>
          <VBtn value="crm" width="120px" class="text-transform-none">
            CRM {{ $t('crm.suit.edit.items') }}
          </VBtn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="4" class="px-2 py-1">
        <VTextField
          prepend-inner-icon="mdi-magnify"
          dense
          :label="$t('form.search')"
          v-model="searchString"
          @input="onSearchProduct"
          hide-details="true"
          clearable
          ref="searchField"
          solo
          :loading="isLoadingSearch"
          color="success"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" justify="space-between" align="center">
      <v-col cols="auto" class="py-0 my-0">
        <chip-tab
          :items="chipTabsItems"
          @onClickChipTab="onClickChipTab"
          :loadingChips="loadingChips || Boolean(searchString)"
          :resetChip="Boolean(searchString)"
        />
      </v-col>
    </v-row>

    <VSimpleTable
      fixed-header
      class="my-table supply"
      style="min-height:300px"
      dense
      v-if="products.length && !isloadingProducts"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="x-sm"></th>
            <th>
              <v-row
                align="center"
                justify="start"
                class="cursor-pointer"
                @click="nameFilterA = !nameFilterA"
                >{{ $t('crm.name') }}
                <div class="d-flex flex-column" align="center" no-gutters>
                  <VIcon dense style="line-height:0.4" :color="getColorForUp">
                    mdi-menu-up
                  </VIcon>
                  <VIcon dense style="line-height:0.4" :color="getColorForDown">
                    mdi-menu-down
                  </VIcon>
                </div>
              </v-row>
            </th>
            <th>{{ $t("table.bar_code") }}</th>
            <th v-if="isOption">{{ $t("table.option") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.uuid">
            <td class="x-sm">
              <img
                v-if="((!product?.product_details?.path_img_thumb && !product?.path_img) && isImgLoaded || !imagesCheckbox)"
                :src="require('@/assets/images/supply-item.png')"
                class="image-placeholder"
              />
              <v-img
                v-else
                :src="product?.product_details?.path_img_thumb || product?.path_img"
                class="image"
                v-show="isImgLoaded"
                @load="showImg"
              >
                <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    :size="20"
                    :width="2"
                    color="green lighten-1"
                  ></v-progress-circular>
                </v-row>
              </template>
              </v-img>
            </td>
            <td>{{ product?.product_details?.name || product?.name }}</td>
            <td>{{ product?.product_details?.bar_code || product?.bar_code }}</td>
            <td class="lg" v-if="isOption">
              <VSelect
                v-if="typeof product?.options_details !== 'undefined'"
                :items="product?.options_details"
                item-text="name"
                item-value="uuid"
                :placeholder="$t('supply.choose_option')"
                :no-data-text="$t('table.noData')"
                v-model="checkOption"
                hide-details
                dense
                outlined
                color="success"
                item-color="success"
              >
                <template slot="item" slot-scope="{ item }">
                  {{ item.name }}
                </template>
                <template slot="selection" slot-scope="{ item }">
                  {{ item.name }}
                </template>
              </VSelect>
              <span v-else> --- </span>
            </td>
            <td>
              <VBtn
                icon
                v-if="typeof product?.options_details !== 'undefined'"
                @click="setProductOption($event, product, 'addProduct')"
              >
                <VIcon>mdi-plus-box-multiple</VIcon>
              </VBtn>
              <VBtn icon v-else @click="setProduct($event, product, 'addProduct')">
                <VIcon>mdi-plus-box-multiple</VIcon>
              </VBtn>
            </td>
          </tr>
          <tr>
            <div
              v-observer="{ nextPage: nextPage }"
              class="observer my-3"
              v-if="!loadMoreDisabled"
            ></div>
          </tr>
        </tbody>
      </template>
    </VSimpleTable>
    <div v-if="isloadingNextProducts">
      <v-progress-linear indeterminate />
    </div>
    <div class="text-center mt-1" v-if="!products.length && !isloadingProducts">
      {{ $t("table.noData") }}
    </div>
    <div class="products-loader__wrapper" v-if="isloadingProducts">
      <div class="products-loader__inner"></div>
      <v-progress-circular
        indeterminate
        color="success"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "../../../../../../mixins/language";
import notifications from "../../../../../../mixins/notifications";
import chipTab from "@/components/common/chipTab.vue";
import depotService from "@/services/request/depot/depotService";
import user from "@/mixins/user";
import EventBus from "@/events/EventBus";
import crmService from "@/services/request/crm/crmService";

export default {
  name: "crmFormListProduct",
  props: {
    isOption: {
      required: false,
      default: true
    },
    isCRMProducts: {
      required: false,
      default: true
    },
    crmTabVision: {
      required: false,
      default: false
    }
  },
  data: () => ({
    imagesCheckbox: true,
    checkOption: "",
    searchString: "",
    isLoadingSearch: false,
    loadMoreDisabled: false,
    loadingChips: false,
    isImgLoaded: false,
    isloadingNextProducts: false,
    isloadingProducts: true,
    tableAvaibleProductsFilter: {},
    chipTabsItems: {},
    products: [],
    nameFilterA: null,
    page: 1,
    perPage: 10,
    productsCategory: 'deport'
  }),
  methods: {
    onBack(){
      this.$emit("back");
    },
    async onClickChipTab(e) {
      this.categoryChipTab = e;
      this.isloadingProducts = true;
      this.loadingChips = true;
      this.tableAvaibleProductsFilter = {};


      if (e === this.$t("btn.all")) {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts();
      } else {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts({
          product_details__name: e,
          uselike: true
        });
      }
      this.nameFilterA = null;
      this.isloadingProducts = false;
      this.loadingChips = false;
    },
    onSearchProduct: _.debounce(async function() {
      if (!this.searchString) {
        this.categoryChipTab = null;
      }
      if (this.searchString) {
        this.isLoadingSearch = true;
        if (this.productsCategory == "crm") {
          await this.getProducts({
            name: this.searchString,
            uselike: true
          });
        } else {
          await this.getProducts({
            product_details__name: this.searchString,
            uselike: true
          });
        }
        this.oldSerach = this.searchString;
        setTimeout(() => {
          this.isLoadingSearch = false;
        }, 300);
      } else {
        await this.getProducts();
      }
      this.$refs.searchField.focus();
    }, 500),
    async nextPage() {
      this.page += 1;
      const params = {};
      if (this.categoryChipTab || this.searchString !== "") {
        if (this.categoryChipTab && this.categoryChipTab !== this.$t("btn.all")) {
          params.product_details__name = this.categoryChipTab;
          params.uselike = true;
        }
        if (this.searchString !== "") {
          params.product_details__name = this.searchString;
          params.uselike = true;
        }
      }
      await this.getProducts(params, true);
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    async getChipTabsItems() {
      try {
        const params = {};
        if (!this.permissions.client) {
          params.uuid_user = this.item.uuid_user;
        }
        let response = null;
        if (this.productsCategory == "crm") {
          response = await crmService.getItemsChipListCRM();
        } else {
          response = await crmService.getItemsChipList(params);
        }
        const chipItems = response;
        this.chipTabsItems = chipItems;
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getProducts(params = {}, next = false) {

      try {
        if (!next) {
          this.page = 1;
        }
        if (next && this.tableAvaibleProductsFilter) {
          params.order = this.tableAvaibleProductsFilter.order;
          params.orderby = this.tableAvaibleProductsFilter.orderby;
        }
        if (next) {
          this.isloadingNextProducts = true;
        } else {
          this.isloadingProducts = true;
        }
        let response = null;
        if (this.productsCategory == "crm") {
          response = await crmService.onlyCrmProductList({
            ...params,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage
          });
        } else {
          response = await depotService.getMyProducts({
            groupby: "uuid_product",
            // groupby: 'product_details.bar_code',
            id_state: 810,
            ...params,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uuid_user: this.getUserProperty('uuid')
          });
        }

        const newItems = response;

        if (next) {
          this.products.push(...newItems);
        } else {
          this.products = newItems;
        }


        this.loadMoreDisabled = newItems.length < this.perPage;
        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
      } catch (e) {
        if (next) {
          this.isloadingNextProducts = false;
        } else {
          this.isloadingProducts = false;
        }
        this.isloadingProducts = false;
        console.log(e)
      }
    },
    setProduct(event, product, type) {
      EventBus.$emit("crm-form-setProduct", product, this.productsCategory == 'crm' ? 'uuid' : 'uuid_product');
      EventBus.$emit("on-tab-list-product", false);
    },
    setProductOption(event, product, type) {
      const productOption = product.options_details.filter(item => item.uuid === this.checkOption);
      if (productOption.length) {
        product.uuid_option = this.checkOption;
        EventBus.$emit("crm-form-setProduct", product,this.productsCategory == 'crm' ? 'uuid' : 'uuid_product');
        EventBus.$emit("on-tab-list-product", false);
        this.checkOption = ''

      } else {
        this.setErrorNotification(this.$t("supply.choose_option"));
      }
    }
  },
  mounted() {
    if(this.isCRMProducts){
      this.productsCategory = 'crm'
    } else {
      this.productsCategory = 'deport'
    }
    this.getProducts();
    this.getChipTabsItems();
  },
  watch: {
    imagesCheckbox: {
      handler() {
        if (this.productsCategory == 'crm') {
          this.fetchCrmCheck
        } else {
          this.fetchDeliveriesCheck
        }
      }
    },
    productsCategory: {
      handler() {
        this.searchString = ''
        this.getProducts();
        this.getChipTabsItems();
      }
    },
    isCRMProducts: {
      handler() {
        this.getProducts();
        if (this.productsCategory == 'crm') {
          this.imagesCheckbox = this.crmImagesCheck
        }
        else {
          this.imagesCheckbox = this.deliveriesImagesCheck
        }
      }
    }
  },
  computed: {
    getColorForUp() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey darken-2";
        } else {
          return "grey lighten-1";
        }
      } else {
        return "grey lighten-1";
      }
    },
    getColorForDown() {
      if (this.nameFilterA !== null) {
        if (this.nameFilterA === true) {
          return "grey lighten-1";
        } else {
          return "grey darken-2";
        }
      } else {
        return "grey lighten-1";
      }
    }
  },
  validations: {},
  mixins: [validationMixin, language, notifications, user],
  components: {
    chipTab
  }
};
</script>
<style lang="scss"></style>
