import requestService from "../requestService";

const prefix = "/products/suits";

export default {
  async getList(params) {
    const response = await requestService.get(`${prefix}/list`, params);
    return response?.data?.object;
  },
  async getSuitInfo(uuid) {
    const response = await requestService.get(`${prefix}/info/${uuid}`);
    return response?.data?.object;
  },
  async addSuit(payload) {
    const response = await requestService.post(`${prefix}/add`, payload);
    return response?.data?.object;
  },
  async deleteSuit(uuid) {
    const response = await requestService.delete(`${prefix}/delete/${uuid}`);
    return response?.data?.object;
  },
  async updateSuits(payload) {
    const response = await requestService.post(`${prefix}/update`, payload);
    return response?.data?.object;
  },
  async getProductList(params) {
    const response = await requestService.get(`${prefix}/items/list`, params);
    return response?.data;
  },
  async deleteProduct(uuid) {
    const response = await requestService.delete(`${prefix}/items/delete/${uuid}`);
    return response?.data?.object;
  },
  async addProduct(payload) {
    const response = await requestService.post(`${prefix}/items/add`,payload, {}, false);
    return response?.data?.object;
  },
  async updateProduct(payload) {
    const response = await requestService.post(`${prefix}/items/update`,payload);
    return response?.data?.object;
  }
};

